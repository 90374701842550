import React, { useState } from "react";
import Title from "../../../components/dashboard/Title";
import { classNames } from "../../../lib/utils";
import CreateDeckButton from "../../../components/study/CreateDeckButton";
import DeckList from "../../../components/study/DeckList";
import TopicList from "../../../components/study/TopicList";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import TopicDetail from "../../../components/study/TopicDetail";
import DeckCreate from "../../../components/study/DeckCreate";
import DeckStudy from "../../../components/study/DeckStudy";
// var debounce = require("lodash.debounce");

const tabs = [
  { name: "My Decks", path: "" },
  { name: "Topics", path: "topics" },
];

export default function Study() {
  let location = useLocation();
  const [decks, setDecks] = useState([]);
  const [topics, setTopics] = useState([]);

  // const handleSearch = useCallback(() => {
  //   debounce(text => {
  //     axiosContext.authAxios
  //       .get(`/study/topics/?search=${text}`)
  //       .then(res => setTopics(res.data))
  //       .catch(err => console.log(err));
  //   }, 300);
  // });

  return (
    <>
      <Title title="Study" />
      {/* Toolbar */}
      <div className="max-w-7xl mx-auto py-2 px-4 mt-4 sm:px-6 md:px-8 border-b border-1 flex items-end justify-between">
        <div>
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            <select
              id="tabs"
              name="tabs"
              className="block w-full focus:ring-lime-500 focus:border-lime-500 border-gray-300 rounded-md"
              // defaultValue={view}
              // onChange={e => {
              //   setView(e.target.value);
              // }}
            >
              {tabs.map(tab => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav>
              <ul className="flex space-x-4" aria-label="Tabs">
                <li>
                  <Link to="">
                    <button
                      className={classNames(
                        location.pathname === `/dashboard/study`
                          ? "text-lime-700"
                          : "text-gray-500 hover:text-gray-700",
                        "px-3 py-2 font-medium text-base rounded-md"
                      )}
                    >
                      My Decks
                    </button>
                  </Link>
                </li>
                <li>
                  <Link to="topics">
                    <button
                      className={classNames(
                        location.pathname === `/dashboard/study/topics`
                          ? "text-lime-700"
                          : "text-gray-500 hover:text-gray-700",
                        "px-3 py-2 font-medium text-base rounded-md"
                      )}
                    >
                      Topics
                    </button>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <CreateDeckButton />
      </div>
      {/* Content Area */}
      <div className="max-w-7xl mx-auto py-2 px-4 mt-4 sm:px-6 md:px-8 flex justify-between">
        <Routes>
          <Route path="topics/:id" element={<TopicDetail />} />
          <Route path="create" element={<DeckCreate />} />
          <Route path="deck/:id" element={<DeckStudy />} />
          <Route path="topics" element={<TopicList topics={topics} />} />
          <Route path="" element={<DeckList decks={decks} />} />
        </Routes>
      </div>
    </>
  );
}
