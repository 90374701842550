import React from "react";

function PrivacyPolicy() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Privacy Policy
            </span>
          </h1>
          <p className="mt-2 text-lg text-gray-500 leading-8 text-center">
            Last updated July 20, 2022
          </p>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <p>
            This privacy notice for Milnerva LLC (<strong>"Company"</strong>,
            <strong>"we"</strong>, <strong>"us"</strong>, <strong>"our"</strong>
            ), describes how and why we might collect, store, use and/or share (
            <strong>"process"</strong>) your information when you use our
            services (<strong>"Services"</strong>), such as when you:
          </p>
          <ul role="list">
            <li>
              Download and use our mobile application (Milnerva), or any other
              application of ours that contains or links to this privacy notice
            </li>
            <li>
              Engage with us in other related waysk including any sales,
              marketing, or events
            </li>
          </ul>
          <p>
            <strong>Questions or concerns?</strong> Reading this privacy policy
            will help you understand your privacy rights and choices. If you do
            not agree with our policies and practices, please do not use our
            Services. If you still have any questions or concerns, please
            contact us at support@milnerva.com.
          </p>
          <h2>Summary of Key Points</h2>
          <p>
            This summary provides key points from our privacy notice, but you
            can find out more details about any of these topics by clicking the
            link following each key point or by using our table of contetns
            below to find the section you are looking for.
          </p>
          <p>
            <strong>What personal information do we process?</strong> When you
            visit, use, or navigate our Services, we may process personal
            information depending on how you interact with Milnerva LLC and the
            Services, the choices you make, and the products and features you
            use.
          </p>
          <p>
            <strong>Do we process any sensitive personal information?</strong>{" "}
            We do not process sensitive personal information.
          </p>
          <p>
            <strong>Do we receive any information from third parties?</strong>{" "}
            We do not receive any information from third parties.
          </p>
          <p>
            <strong>How do we process your information?</strong> We process your
            information to provide, improve, and administer our Services,
            communicate with you, for security and fraud prevention, and to
            comply with law. We may also process your information for other
            purposes with your consent. We process your information only when we
            have a valid legal reason to do so.
          </p>

          <p>
            <strong>
              In what situations and with which parties do we share personal
              information?
            </strong>{" "}
            We may share information in specific situations and with specific
            third parties.
          </p>
          <p>
            <strong>How do we keep your information safe?</strong> We have
            organizational and technical processes and procedures in place to
            protect your personal information. However, no electronic
            transmission over the internet or information storage technology can
            be guaranteed to be 100% secure, so we cannot promise or guarantee
            that hackers, cybercriminals, or other unauthorized third parties
            will not be able to defeat our security and improperly collect,
            access, steal, or modify your information.
          </p>
          <p>
            <strong>What are your rights?</strong> Depending on where you are
            located geographically, the applicable privacy law may mean you have
            certain rights regarding your personal information.
          </p>
          <p>
            <strong>How do you exercise your rights?</strong> The easiest way to
            exercise your rights is by filling out our data subject request form
            available at request, or contacting us. We will consider and act
            upon any request in accordance with the applicable data protection
            laws.
          </p>
          <h2>Table of Contents</h2>
          <ol>
            <li>What information do we collect?</li>
            <li>How do we process your information?</li>
            <li>
              What legal bases do we rely on to process your personal
              information?
            </li>
            <li>When and with whom do we share your personal information?</li>
            <li>How long do we keep your information?</li>
            <li>How do we keep your information safe?</li>
            <li>Do we collect information from minors?</li>
            <li>What are your privacy rights?</li>
            <li>Controls for do-not-track features</li>
            <li>Do California residents have specific privacy rights?</li>
            <li>Do we make updates to this notice?</li>
            <li>How can you contact us about this notice?</li>
            <li>
              How can you review, update, or delete the data we collect from
              you?
            </li>
          </ol>
          <h2>1. What information do we collect?</h2>
          <h3>Personal information you disclose to us</h3>
          <p>
            <strong>In short:</strong> We collect personal information that you
            provide to us.
          </p>
          <p>
            We collect personal information that you voluntarily provide to us
            when you register on the Services, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Services, or otherwise when you
            contact us.
          </p>
          <p>
            <strong>Personal Information Provided by You.</strong> The personal
            information that we collect depends on the context of your
            interactions with us and the Services, the choices you make, and the
            products and features you use. The personal information we collect
            may include the following:
          </p>
          <ul>
            <li>email addresses</li>
            <li>usernames</li>
            <li>job titles</li>
            <li>mailing addresses</li>
            <li>phone numbers</li>
            <li>names</li>
            <li>passwords</li>
            <li>contact preferences</li>
            <li>debit/credit card numbers</li>
            <li>billing addresses</li>
            <li>contact or authentication data</li>
          </ul>
          <p>
            <strong>Sensitive Information.</strong> We do not process sensitive
            information.
          </p>
          <p>
            <strong>Application Data.</strong> If you use our application(s), we
            also may collect the following information if you choose to provide
            us with access or permission:
          </p>
          <ul>
            <li>
              <em>Geolocation Information.</em> We may request access or
              permission to track location-based information from your mobile
              device, either continuously or while you are using our mobile
              application(s), to provide certain location-based services. If you
              wish to change our access or permissions, you may do so in your
              device's settings.
            </li>
            <li>
              <em>Mobile Device Access.</em> We may request access or permission
              to certain features from your mobile device, including your mobile
              device's calendar, camera, storage, and other features. If you
              wish to change our access or permissions, you may do so in your
              device's settings.
            </li>
            <li>
              <em>Mobile Device Data.</em> We automatically collect device
              information (such as your mobile device ID, model, and
              manufacturer), operating system, version information and system
              configuration information, device and application identification
              numbers, browser type and version, hardware model Internet service
              provider and/or mobile carrier, and Internet Protocol (IP) address
              (or proxy server). If you are using our application(s), we may
              also collect information about the phone network associated with
              your mobile device, your mobile device's operating system or
              platform, the type of mobile device you use, your mobile device's
              unique device ID, and information about the features of our
              application(s) you accessed.
            </li>
            <li>
              <em>Push Notifications.</em> We may request to send you push
              notifications regarding your account or certain features of the
              application(s). If you wish to opt out from receiving these types
              of communications, you may turn them off in your device's
              settings.
            </li>
          </ul>
          <p>
            This information is primarily needed to maintain the security and
            operation of our application(s), for troubleshooting, and for our
            internal analytics and reporting purposes.
          </p>
          <p>
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>
          <h3>Information automatically collected</h3>
          <p>
            <strong>In short:</strong> Some information -- such as your Internet
            Protocol (IP) address and/or browser and device characteristics --
            is collected automatically when you visit our Services.
          </p>

          <p>
            We automatically collect certain information when you visit, use, or
            navigate the Services. This information does not reveal your
            specific identity (like your name or contact information) but may
            include device and usage information, such as your IP address,
            browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location,
            information about how and when you use our Services, and other
            technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our
            internal analytics and reporting purposes.
          </p>
          <p>
            Like man businesses, we also collect information through cookies and
            similar technologies.
          </p>

          <p>The information we collect includes:</p>
          <ul>
            <li>
              <em>Log and Usage Data.</em> Log and usage data is
              service-related, diagnostic, usage, and performance information
              our servers automatically collect when you access or use our
              Services and which we record in log files. Depending on how you
              interact with us, this log data may include your IP address,
              device information, browser type, and settings and information
              about your activity in the Services (such as the date/time stamps
              associated with your usage, pages and files viewed, searches, and
              other actions you take such as which features you use), device
              event information (such as system activity, error reports
              (sometimes called "crash dumps"), and hardware settings).
            </li>
            <li>
              <em>Device Data.</em> We collect device data such as information
              about your computer, phone, tablet, or other device you use to
              access the Services. Depending on the device used, this device
              data may include information such as your IP address (or proxy
              server), device and application identification numbers, location,
              browser type, hardware model, Internet service provider and/or
              mobile carrier, operating system, and system configuration
              information.
            </li>
            <li>
              <em>Location Data.</em> We collect location data such as
              information about your device's location, which can be either
              precise or imprecise. How much information we collect depends on
              the type and settings of the device you use to access the
              Services.For example, we may use GPS and other technologies to
              collect geolocation data that tells us your current location
              (based on your IP address). You can opt out of allowing us to
              collect this information either by refusing access to the
              information or by disabling your Location setting on your device.
              However, if you choose to opt out, you may not be able to use
              certain aspects of the Services.
            </li>
          </ul>
          <h2>2. How do we process your information?</h2>
          <p>
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
          </p>
          <ul>
            <li>
              <strong>
                To facilitate account creation and authentication and otherwise
                manage user accounts.
              </strong>{" "}
              We may process your information so you can create and log in to
              your account, as well as keep your account in working order.
            </li>
            <li>
              <strong>
                To deliver and facilitate delivery of services to the user.
              </strong>{" "}
              We may process your information to provide you with the request
              service.
            </li>
            <li>
              <strong>
                To respond to user inquiries/offer support to users.
              </strong>{" "}
              We may process your information to send you details about our
              products and services, changes to our terms and policies, and
              other similar information.
            </li>
            <li>
              <strong>To fulfill and manage your orders.</strong> We may process
              your information to fulfill and manage your orders, payments,
              returns, and exchanges made through the Services.
            </li>
            <li>
              <strong>To enable user-to-user communications.</strong> We may
              process your information if you choose to use any of our offerings
              that allow for communication with another user.
            </li>
            <li>
              <strong>To request feedback.</strong> We may process your
              information when necessary to request feedback and to contact you
              about your use of Services.
            </li>
            <li>
              <strong>To protect our Services.</strong> We may process your
              information as part of our efforts to keep our Services safe and
              secure, including fraud monitoring and prevention.
            </li>
            <li>
              <strong>To identify usage trends.</strong> We may process
              information about how you use our Services to better understand
              how they are being used so we can improve them.
            </li>
            <li>
              <strong>
                To save or protect an individual's vital interest.
              </strong>{" "}
              We may process your information when necessary to save or protect
              an individual's vital interest, such as to prevent harm.
            </li>
          </ul>
          <h2>
            3. What legal bases do we rely on to process your information?
          </h2>
          <p>
            <strong>In short:</strong> We only process your information when we
            believe it is necessary and we have a valid legal reason (i.e. legal
            basis) to do so under applicable law, like with your consent, to
            comply with laws, to provide you with services to enter into or
            fulfill our contractual obligations, to protect your rights, or to
            fulfill our legitimate business interests.
          </p>
          <p>
            <strong>
              If you are located in the EU or UK, this section applies to you.
            </strong>
          </p>
          <p>
            The General Data Protection Regulation (GDPR) and UK GDPR require us
            to explain the valid legal bases we rely on in order to process your
            personal information. As such, we may rely on the following legal
            bases to process your personal information:
          </p>
          <ul>
            <li>
              <strong>Consent.</strong> We may process your information if you
              have given us permission (i.e., consent) to use your personal
              information for a specific purpose. You can withdraw your consent
              at any time.
            </li>
            <li>
              <strong>Performance of a Contract.</strong> We may process your
              personal information when we believe it is necessary to fultill
              our contractual obligations to you, including our providing our
              Services or at your request prior to entering into a contract with
              you.
            </li>
            <li>
              <strong>Legitimate interests.</strong> We may process your
              information when we believe it is reasonably necessary to achieve
              our legitimate business interests and those interests do not
              outweigh your interests and fundamental rights and freedoms. For
              example, we may process your personal information for some of the
              purposes described in order to:
              <ul>
                <li>
                  Analyze how our services are used so we can improve them to
                  engage and retain users
                </li>
                <li>Diagnose problems and/or prevent fraudelent activities</li>
                <li>
                  Understand how our users use our products and services so we
                  can improve user experiences
                </li>
              </ul>
            </li>
            <li>
              <strong>Legal obligations.</strong> We may process your
              information where we believe it is necessary for compliance with
              our legal obligations, such as to cooperate with a law enforcement
              body or regulatory agency, exercise or defend our legal rights, or
              disclose your information as evidence in litigation in which we
              are involved.
            </li>
            <li>
              <strong>Vital interests.</strong> We may process your information
              where we believe it is necessary to protect your vital interests
              or the vital interests of a third party, such as situations
              involving potential threats to the safety of any person.
            </li>
          </ul>
          <p>
            <strong>
              If you are located in Canada, this section applies to you.
            </strong>
          </p>
          <p>
            We may process your information if you have given us specific
            permission (i.e. express consent) to use your personal information
            for a specific purpose, or in situations where your permission can
            be inferred (i.e. implied consent). You can withdraw your consent at
            any time.
          </p>
          <p>
            In some execeptional cases, we may be legally permitted under
            applicable law to process your information without your consent,
            including, for example:
          </p>
          <ul>
            <li>
              If collection is clearly in the interests of an individual and
              consent cannot be obtained in a timely way
            </li>
            <li>For investigations and fraud detection and prevention</li>
            <li>
              For business transactions provided certain conditions are met
            </li>
            <li>
              If it is contained in a witness statement and the collection is
              necessary to assess, process, or settle an insurance claim
            </li>
            <li>
              For identifying injured, ill, or deceased persons and
              communicating with next of kin
            </li>
            <li>
              If we have reasonable grounds to believe in an individual has
              been, is, or may be victim of financial abuse
            </li>
            <li>
              If it is reasonable to expect collection and use with consent
              would compromise the availability or the accuracy of the
              information and the collection is reasonable for purposes related
              to investigating a breach of an agreement or a contravention of
              the laws of Canada or a province.
            </li>
            <li>
              If disclosure is required to comply with a subpoena, warrant,
              court order, or rules of the court relating to the production of
              records
            </li>
            <li>
              If it was prouced by an individual in the course of their
              employment, business, or profession and the collection is
              consistent with the purposes for which the information was
              produced
            </li>
            <li>
              If the collection is solely for journalistic, artistic, or
              literary purposes
            </li>
            <li>
              If the information is publicly available and is specified by the
              regulations
            </li>
          </ul>
          <h2>4. When and with whom do we share your personal information?</h2>
          <p>
            <strong>In short:</strong> We may share information in specific
            situations described in this section and/or with the following third
            parties.
          </p>
          <p>
            We may need to share your personal information in the following
            situations:
          </p>
          <ul>
            <li>
              <strong>Business transfers.</strong> We may share or transfer your
              information in connection with, or during negotiations of, any
              merger, sale of company assets, financing, or acquisition of all
              or a portion of our business to another company
            </li>
          </ul>
          <h2>5. Do we use cookies and other tracking technologies?</h2>
          <p>
            <strong>In short:</strong> We may share information in specific
            situations described in this section and/or with the following third
            parties.
          </p>
          <p>
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to access or store information. Specific
            information about how we use such technologies and how you can
            refuse certain cookies is set out in our Cookie Notice.
          </p>
          <h2>6. How long do we keep your information?</h2>
          <p>
            <strong>In short:</strong> we keep your information for as long as
            necessary to fulfill the purposes outlined in this privacy notice
            unless otherwise required by law.
          </p>
          <p>
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements). No purpose in this
            notice will require us keeping your personal information for longer
            than the period of time in which users have an account with us.
          </p>
          <p>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>
          <h2>7. How do we keep your information safe?</h2>
          <p>
            <strong>In short:</strong> We aim to protect your personal
            information through a system of organizational and technical
            security measures.
          </p>
          <p>
            We have implemented appropriate and reasonable technical and
            organizational security measures designed to protect the security of
            any personal information we process. However, despite our safeguards
            and efforts to secure your information, no electronic transmission
            over the Internet or information storage technology can be
            guaranteed to be 100% secure, so we cannot promise or guarantee that
            hackers, cybercriminals, or other unauthorized third parties will
            not be able to defeat our security and improperly collect, access,
            steal, or modify your information. Although we will do our best to
            protect yoru personal information, transmission of personal
            information to and from our Services is at your own risk. You should
            only access the Services within a secure environment.
          </p>
          <h2>8. Do we collect information from minors?</h2>
          <p>
            <strong>In short:</strong> we do not knowingly collect data from or
            market to children under 18 years of age.
          </p>
          <p>
            We do not knowingly solicit data from or market to children under 18
            years of age. By using the Services, you represent that you are at
            least 18 or that you are the parent or guardian of such a minor and
            consent to such minor dependent's use of the Services. If we learn
            that personal information from users less than 18 years of age has
            been collected, we will deactivate the account and take reasonable
            measures to promptly delete such data from our records. If you
            become aware of any data we may have collected from children under
            age 18, please contact support@milnerva.com.
          </p>
          <h2>9. What are your privacy rights?</h2>
          <p>
            <strong>In short:</strong> In some regions, such as the European
            Economic Area (EEA), United Kingdom (UK), and Canada, you have
            rights that allow you greater access to and control over your
            personal information. You may review, change, or terminate your
            account at any time.
          </p>
          <p>
            In some regions (like the EEA, UK, and Canada), you have certain
            rights under applicable data protection laws. These may include teh
            right (i) to request access and obtain a copy of your personal
            information, (ii) to request rectification or erasure; (iii) to
            restrict the processing of your personal information; and (iv) if
            applicable, to data portability. In certain circumstances, you may
            also have the right to object to the processing of your personal
            information. You can make such a request by contacting us by using
            the contact details provided in the section "How can you contact us
            about this notice?" below.
          </p>
          <p>
            We will consider and act upon any request in accordance with
            applicable data protection laws.
          </p>
          <p>
            If you are located in the EEA or UK and you believe we are
            unlawfully processing your personal information, you also have the
            right to complain to your local data protection supervisory
            authority. You can find their contact details here:
            <a
              href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html"
              style={{
                paddingLeft: 3,
              }}
            >
              https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html
            </a>
            .
          </p>
          <p>
            If you are located in Switzerland, the contact details for the data
            protection authorities are available here:
            <a
              href="https://www.edoeb.admin.ch/edoeb/en/home.html"
              style={{
                paddingLeft: 3,
              }}
            >
              https://www.edoeb.admin.ch/edoeb/en/home.html
            </a>
            .
          </p>
          <p>
            <strong>Withdrawing your consent:</strong> If we are relying on your
            consent to process your personal information, which may be express
            and/or implied consent depending on the applicable law, you have the
            right to withdraw your consent at any time. You can withdraw your
            consent at any time by contacting us by using the contact details
            provided in the section "How can you contact us about this notice?"
            below or updating your preferences.
          </p>
          <p>
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal, nor when applicable law allows,
            will it affect the processing of your personal information conducted
            in reliance on lawful processing grounds other than consent.
          </p>
          <p>
            <strong>
              <u>Opting out of marketing and promotional communications:</u>
            </strong>{" "}
            You can unsubscribe from our marketing and promotional
            communications at any time by clicking on the unsubscribe link in
            the emails that we send, or by contacting us using the details
            provided in the section "How can you contact us about this notice?"
            below. You will then be removed from the marketing lists. However,
            we may still communicate with you -- for example, to send you
            service-related messages that are necessary for the administration
            and use of your account, to response to service requests, or for
            other non-marketing purposes.
          </p>
          <h3>Account Information</h3>
          <p>
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </p>
          <ul>
            <li>
              Log in to your account settings and update your user account.
            </li>
          </ul>
          <p>
            Upon your request terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal
            requirements.
          </p>
          <p>
            <strong>
              <u>Cookies and similar technologies:</u>
            </strong>{" "}
            Most Web browsers are set to accept cookies by default. If you
            prefer, you can usually choose to set your browser to remove cookies
            and reject cookies. If you choose to remove cookies or reject
            cookies, this could affect certain features or services of our
            Services. To opt out of interest-based advertising by advertisers on
            our Services visit{" "}
            <a href="http://www.aboutads.info/choices/">
              http://www.aboutads.info/choices/
            </a>
            .
          </p>
          <p>
            If you have questions or comments about your privacy rights, you may
            email us at support@milnerva.com
          </p>
          <h2>10. Controls for do-not-track features</h2>
          <p>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage, no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. AS such, we do not
            currently response to DNT browser signals or any other mechanism
            that automatically communicates your choice not to be tracked
            online. If a standard for online tracking is adopted that we must
            follow in the future, we will inform you about that practice in a
            revised version of this privacy notice.
          </p>
          <h2>11. Do California residents have specific rights?</h2>
          <p>
            <strong>In short:</strong> Yes, if you are a resident of California,
            you are granted specific rights regarding access to your personal
            information.
          </p>
          <p>
            California Civil Code Section 1798.83, also known as the "Shine the
            Light" law, permits our users who are California residents to
            request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and address of all third parties with which we shared personal
            information in the immediately preceding calendar year. If you are a
            California resident and would like to make such a request, please
            submit your request in writing to use using the contact information
            provided below.
          </p>
          <p>
            If you are under 18 years of age, reside in California, nad have a
            registered account with Services, you have the right to request
            removal of unwanted data that you publicly post on the Services. To
            request removal of such data, please contact us using the contact
            information provided below and include the email address associated
            with your account and a statement that you reside in California. We
            will make sure the data is not publicly displayed on the Services,
            but please be aware that the data may not be completely or
            comprehensively removed from all our systems (e.g. backups, etc.).
          </p>
          <h3>CCPA Privacy Notice</h3>
          <p>The California Code of Regulations defines a "resident" as:</p>
          <ol>
            <li>
              every individual who is in the State of California for other than
              a temporary or transitory purpose and
            </li>
            <li>
              every individual who is domiciled in the State of California who
              is outside the State of California for a temporary or transitory
              purpose
            </li>
          </ol>
          <p>All other individuals are defined as "non-residents."</p>
          <p>
            If this definition of "resident" applies to you, we must adhere to
            certain rights and obligations regarding your personal information.
          </p>
          <p>
            <strong>
              What categories of personal information do we collect?
            </strong>
          </p>
          <p>
            We have collected the following categories of personal information
            in the past twelve (12) months:
          </p>
          <table>
            <thead>
              <tr>
                <th>Category</th>
                <th>Examples</th>
                <th>Collected</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>A. Identifiers</td>
                <td>
                  Contact details, such as real name, alias, postal address,
                  telephone or mobile contact number, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address, and account name
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>
                  B. Personal information categories listed in the California
                  Customer Records statute
                </td>
                <td>
                  Name, contact information, education, employment, employment
                  history, and financial information
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>
                  C. Protected classification characteristics under California
                  or federal law
                </td>
                <td>Gender and date of birth</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>D. Commercial information</td>
                <td>
                  Transaction information, purchase history, financial details
                  and payment information
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>E. Biometric Information</td>
                <td>Fingerprints and voiceprints</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>F. Internet or other similar network activity</td>
                <td>
                  Browsing history, search history, online behavior, interest
                  data, and interactions with our and other websites,
                  applications, systems, and advertisements
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>G. Geolocation data</td>
                <td>Device location</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>
                  H. Audio, electronic, visual, thermal, olfactory or similar
                  information
                </td>
                <td>
                  Images and audio, video or call recordings created in
                  connection with our business activities
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>I. Professional or employment-related information</td>
                <td>
                  Business contact details in order to provide you our services
                  at a business level or job title, work history, and
                  professional qualifications if you apply for a job with us
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>J. Education information</td>
                <td>Student records and directory information</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>K. Inferences drawn from other personal information</td>
                <td>
                  Inferences drawn from any of the collected personal
                  information listed above to create a profile or summary about,
                  for example, an indvidual's preferences and characteristics
                </td>
                <td>NO</td>
              </tr>
            </tbody>
          </table>
          <p>
            We may also collect other personal information outside of these
            categories instances where you interact with us in person, online,
            or by phone or mail in the context of:
          </p>
          <ul>
            <li>Receiving help through our customer support channels;</li>
            <li>Participation in customer surveys or contests; and</li>
            <li>
              Facilitation in the delivery of our Services and to response to
              your inquiries.
            </li>
          </ul>
          <p>
            <strong>How do we use and share your personal information?</strong>
          </p>
          <p>
            More information about our data collection and sharing practices can
            be found in this privacy notice.
          </p>
          <p>
            You may contact us by email at support@milnerva.com, or by referring
            to the contact details at the bottom of this document.
          </p>
          <p>
            If you are using an authorized agent to exercise your right to opt
            out we may deny a request if the authorized agent does not submit
            proof that they have been validly authorized to act on your behalf.
          </p>
          <p>
            <strong>Will your information be shared with anyone else?</strong>
          </p>
          <p>
            We may disclose your personal information with our service providers
            pursuant to a written contract between us and each service provider.
            Each service provider is a for-profit entity that processes the
            information on our behalf.
          </p>
          <p>
            We may use your personal information for our own business purposes,
            such as for undertaking internal research for technological
            development and demonstration. This is not considered to be
            "selling" of your personal information.
          </p>
          <p>
            Milnerva LLC has not disclosed or sold any personal information to
            third parties for a business or commercial purpose in the preceding
            twelve (12) months. Milnerva LLC will not sell personal information
            in the future belong to website visitors, users, and other
            consumers.
          </p>
          <p>Your rights with respect to your personal data</p>
          <p>
            <u>Right to request deletion of the data -- Request to delete</u>
          </p>
          <p>
            You can ask for the deletion of your personal information. If you
            ask us to delete your personal information, we will respect your
            request and delete your personal information, subject to certain
            exceptions provided by law, such as (but not limited to) the
            exercise by another consumer of his or her right to free speech, our
            compliance requirements resulting from a legal obligation, or any
            processing that may be required to protect against illegal
            activities.
          </p>
          <p>
            <u>Right to be informed -- Request to know</u>
          </p>
          <p>Depending on the circumstances, you have a right to know:</p>
          <ul>
            <li>whether we collect and use your personal information;</li>
            <li>the categories of personal information that we collect;</li>
            <li>
              the purposes for which the collected personal information is used;
            </li>
            <li>whether we sell your information to third parties;</li>
            <li>
              the categories of personal information that we sold or disclosed
              for a business purpose;
            </li>
            <li>
              the categories of third parties to whom the personal information
              was sold or disclosed for a business purpose; and
            </li>
            <li>
              the business or commercial purpose for collecting or selling
              personal information.
            </li>
          </ul>
          <p>
            In accordance with applicable law, we are not obligated to provide
            or delete consumer information that is de-identified in response to
            a consumer request or to re-identify individual data to verify a
            consumer request.
          </p>
          <p>
            <strong>
              <u>
                Right to Non-Discrimination for the Exercise of a Consumer's
                Privacy Rights
              </u>
            </strong>
          </p>
          <p>
            We will not discriminate against you if you exercise your privacy
            rights.
          </p>
          <p>
            <strong>
              <u>Verification process</u>
            </strong>
          </p>
          <p>
            Upon receiving your request, we will need to verify your identity to
            determine you are the same person about whom we have the information
            in our system. These verification efforts require us to ask you to
            provide information so that we can match it with information you
            have previously provided us. For instance, depending on the type of
            request you submit, we may ask you to provide certain information so
            that we can match the information you provide with the information
            we already have on file, or we may contact you through a
            communication method (e.g. phone or email) that you have previously
            provided to us. We may also use other verification methods as the
            circumstances dictate.
          </p>
          <p>
            We will only use personal information provided in your request to
            verify your identity or authority to make the request. To the extent
            possible, we will avoid requesting additional information from you
            for the purposes of verification. However, if we cannot verify your
            identity from the information already maintained by us, we may
            request that you provide additional information for the purposes of
            verifying your identity and for security or fraud-prevention
            purposes. We will delete such additionally provided information as
            soon as we finish verifying you.
          </p>
          <p>
            <strong>
              <u>Other privacy rights</u>
            </strong>
          </p>
          <ul>
            <li>You may object to the processing of your information.</li>
            <li>
              You may request correction of your personal data if it is
              incorrect or no longer relevant, or ask to restrict the processing
              of the information.
            </li>
            <li>
              You can designated an authorized agent to make a request under the
              CCPA on your behalf. We may deny a request from an authorized
              agent that does not submit proff that they have been validly
              authorized to act on your behalf in accordance with the CCPA.
            </li>
            <li>
              You may request to opt out from future selling of your personal
              information to third parties. Upon receiving an opt-out request,
              we will act upon the request as soon as feasibly possible, but no
              later than fifteen (15) days from the date of the request
              submission.
            </li>
          </ul>
          <p>
            To exercise these rights, you can contact us by email at
            support@milnerva.com, or by referring to the contact details at the
            bottom of this document. If you have a complaint about how we handle
            your data, we would like to hear from you.
          </p>
          <h2>12. Do we make updates to this notice?</h2>
          <p>
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated "Revised" data and the
            updated version will be effective as soon as it is accessible. If we
            make material changes ot this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </p>
          <h2>13. How can you contact us about this notice?</h2>
          <p>
            If you have questions or comments about this notice you may email us
            at support@milnerva.com or by post to:
          </p>
          <p>
            Milnerva LLC
            <br />
            8021 N FM 620 RD Unit 632
            <br />
            Austin, TX 78726
            <br />
            United States
          </p>
          <h2>
            14. How can you review, update, or delete the data we collect from
            you?
          </h2>
          <p>
            Baed on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it. To request to review, update,
            or delete your personal information, please submit a request form by
            emailing us at support@milnerva.com.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
