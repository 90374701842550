import React from "react";
import Title from "../../../components/dashboard/Title";

export default function Publications() {
  return (
    <>
      <Title title="Topics" />
    </>
  );
}
