import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { AxiosContext } from "../../context/AxiosProvider";
import Loading from "../Loading";

export default function TopicList() {
  const [loading, setLoading] = useState(true);
  const axiosContext = useContext(AxiosContext);
  const [topics, setTopics] = useState([]);
  let isLoading = loading;

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        await axiosContext.authAxios
          .get(`/study/topics/`)
          .then(res => {
            setTopics(res.data);
            setLoading(false);
          })
          .catch(err => console.log(err));
      } catch (err) {
        console.log(err);
      }
    };

    fetchTopics();
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <div>
      <h3 className="font-semibold text-2xl">Topics</h3>
      <div className="py-6">
        <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-4">
          {topics?.map(topic => (
            <Link key={topic.id} to={`${topic.id}`}>
              <div className="relative flex items-center justify-between w-64 p-4 rounded-md shadow bg-white">
                <div className="min-w-0 flex-1">
                  <label
                    htmlFor={topic.name}
                    className="font-medium text-gray-900"
                  >
                    {topic.name}
                  </label>
                  <p id={topic.short_name} className="text-lime-700 text-sm">
                    {topic.short_name}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
