import React from "react";
import { classNames } from "../../lib/utils";

export default function FlashCard({ prompt, answer, visible, setVisible }) {
  const onClickHandler = () => {
    setVisible(true);
  };

  return (
    <div
      onClick={onClickHandler}
      className="cursor-pointer bg-white shadow-lg rounded-lg border border-gray-100 flex flex-col items-center justify-around py-16 px-6 h-96 w-96 md:w-4/5 lg:w-3/4"
    >
      <div className="py-16 text-2xl font-semibold">{prompt}</div>
      <div className="border border-gray-50 w-full"></div>
      <div
        className={classNames(
          visible ? "text-gray-900" : "text-white",
          "py-16 px-8 text-xl"
        )}
      >
        {answer}
      </div>
    </div>
  );
}
