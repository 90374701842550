export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const validUsername = username => {
  const re = /^[A-Za-z0-9@/\\.+-_]{5,150}$/;
  if (!re.test(username)) {
    return false;
  }
  return true;
};

export const validEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(email)) {
    return false;
  }
  return true;
};

export const validPassword = password => {
  const re = /^[A-Za-z0-9@/\\.!+-_]{5,150}$/;
  if (!re.test(password)) {
    return false;
  }
  return true;
};

export const validTopicName = topicName => {
  const len = topicName.length;
  if (len < 4 || len > 256) {
    return false;
  }
  return true;
};

export const validQuestion = question => {
  const len = question.length;
  if (len < 4 || len > 256) {
    return false;
  }
  return true;
};

export const validComments = comments => {
  const len = comments.length;
  if (len < 4 || len > 500) {
    return false;
  }
  return true;
};
