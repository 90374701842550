import React, { Fragment, useState, useEffect, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { createPortal } from "react-dom";
import { Link } from "react-router-dom";
import { TrashIcon, AcademicCapIcon } from "@heroicons/react/outline";

export default function DeckDetailOverlay({ open, setOpen, deck }) {
  return createPortal(
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-30 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <div className="flex flex-row items-center">
                          <Dialog.Title className="font-semibold text-3xl">
                            {deck?.deck.name}
                          </Dialog.Title>
                          <span className="ml-3 text-base pt-1 text-gray-600">
                            {deck?.count.cards.total} cards
                          </span>
                        </div>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 px-4 py-8 sm:px-6 flex justify-around">
                      <div className="pr-6 flex flex-col items-center">
                        <p className="text-lg text-gray-600">New</p>
                        <span className="font-semibold text-2xl">
                          {deck?.count.cards.new}
                        </span>
                      </div>
                      <div className="px-6 flex flex-col items-center">
                        <p className="text-lg text-gray-600">Learning</p>
                        <span className="font-semibold text-2xl">
                          {deck?.count.cards.learning}
                        </span>
                      </div>
                      <div className="px-6 flex flex-col items-center">
                        <p className="text-lg text-gray-600">Review</p>
                        <span className="font-semibold text-2xl">
                          {deck?.count.cards.review}
                        </span>
                      </div>
                    </div>
                    <div className="pt-2 pb-6 px-6 flex-shrink-0 flex items-center justify-end">
                      <Link to={`/dashboard/study/deck/${deck?.deck.id}`}>
                        <button
                          type="button"
                          className="relative inline-flex items-center px-4 py-2 border border-lime-700 shadow-sm text-sm font-medium rounded-md text-white bg-lime-700 hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-lime-500"
                        >
                          <AcademicCapIcon
                            className="-ml-1 mr-2 h-5 w-5 text-white"
                            aria-hidden="true"
                          />
                          <span>Study</span>
                        </button>
                      </Link>
                      <button
                        type="button"
                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-red-600 shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                      >
                        <TrashIcon
                          className="-ml-1 mr-2 h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                        <span>Delete</span>
                      </button>
                    </div>
                    <div className="py-2 px-6 border-t">
                      <div className="mt-2 flex flex-row items-center">
                        <h4 className="font-semibold text-2xl">Topics</h4>
                        <span className="ml-4 text-base text-gray-600">
                          {deck?.count.topics.total} topics
                        </span>
                      </div>
                      <ul className="mt-2 ml-2">
                        {deck?.deck.topics.map(topic => (
                          <Link
                            key={topic.id}
                            to={`/dashboard/study/topics/${topic.id}`}
                          >
                            <li className="py-2">
                              <span className="pr-2 text-lime-700">
                                {topic.short_name}
                              </span>
                              {topic.name}
                            </li>
                          </Link>
                        ))}
                      </ul>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>,
    document.getElementById("overlay-root")
  );
}
