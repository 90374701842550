import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { AxiosContext } from "../../context/AxiosProvider";
import Loading from "../Loading";
import TopicCardModal from "./TopicCardModal";

export default function TopicDetail() {
  let { id } = useParams();
  const [topicLoading, setTopicLoading] = useState(true);
  const [cardsLoading, setCardsLoading] = useState(true);
  const [topic, setTopic] = useState();
  const [cards, setCards] = useState();
  const [open, setOpen] = useState(false);
  const [prompt, setPrompt] = useState();
  const [answer, setAnswer] = useState();
  const axiosContext = useContext(AxiosContext);

  let isLoading = topicLoading || cardsLoading;

  useEffect(() => {
    const fetchTopic = async () => {
      try {
        await axiosContext.authAxios
          .get(`/study/topics/${id}`)
          .then(res => {
            // if (res.status === 401) {
            //   navigation.navigate("Login");
            // }
            setTopic(res.data);
            setTopicLoading(false);
          })
          .catch(err => console.log(err));
      } catch (error) {
        throw error;
      }
    };
    const fetchTopicCards = async () => {
      try {
        await axiosContext.authAxios
          .get(`/study/topics/${id}/cards`)
          .then(res => {
            // if (res.status === 401) {
            //   navigation.navigate("Login");
            // }
            setCards(res.data);
            setCardsLoading(false);
          })
          .catch(err => console.log(err));
      } catch (error) {
        throw error;
      }
    };
    fetchTopic();
    fetchTopicCards();
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="w-full">
      <TopicCardModal
        open={open}
        setOpen={setOpen}
        prompt={prompt}
        answer={answer}
      />
      <div className="flex flex-col w-full">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center">
            <span className="font-semibold text-2xl py-1 mr-2 text-lime-700">
              {topic?.short_name}
            </span>
            <h3 className="font-semibold text-2xl">{topic?.name}</h3>
          </div>
        </div>
      </div>
      <div className="py-6">
        <div className="flex flex-row items-center">
          <h4 className="font-semibold text-2xl">Cards</h4>
          <span className="ml-4 mt-1 text-base text-gray-600">
            {cards?.count} cards
          </span>
        </div>
        <ul className="mt-2">
          {cards?.cards.map(card => (
            <li key={card.id} className="py-2">
              <button
                className="cursor-pointer hover:text-lime-700 text-left"
                onClick={() => {
                  setPrompt(card.prompt);
                  setAnswer(card.answer);
                  setOpen(true);
                }}
              >
                {card.prompt}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
