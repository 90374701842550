import React, { useEffect, useState, useContext, useRef } from "react";
import { AxiosContext } from "../../context/AxiosProvider";
import Loading from "../Loading";

export default function DeckCreate() {
  const [loading, setLoading] = useState(true);
  const [deckName, setDeckName] = useState("");
  const [topics, setTopics] = useState();
  const [selectedTopics, setSelectedTopics] = useState([]);
  const axiosContext = useContext(AxiosContext);
  const deckNameInputRef = useRef();

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        await axiosContext.authAxios
          .get("/study/topics/")
          .then(res => setTopics(res.data))
          .catch(err => console.log(err));
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchTopics();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <div className="w-full flex flex-col">
      <h2 className="text-2xl font-semibold">Create deck</h2>
      <div className="flex mt-4">
        <div className="w-72">
          <h2 className="text-xl font-semibold mb-3"></h2>
          <div>
            <label
              htmlFor="deckName"
              className="block text-lg font-semibold mb-3"
            >
              Deck Name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="deckName"
                id="deckName"
                className="shadow-sm focus:ring-lime-500 focus:border-lime-700 block w-full h-10 sm:text-sm border-gray-200 rounded-md"
                placeholder="Board Study Guide"
                ref={deckNameInputRef}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <h3 className="text-lg font-semibold my-3">Add Topics</h3>
        <fieldset>
          <legend className="sr-only">Topics</legend>
          <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-4">
            {topics?.map(topic => (
              <div
                key={topic.id}
                className="relative flex items-center justify-between w-64 p-4 rounded-md shadow bg-white"
              >
                <div className="min-w-0 flex-1">
                  <label
                    htmlFor={topic.name}
                    className="font-medium text-gray-900"
                  >
                    {topic.name}
                  </label>
                  <p id={topic.short_name} className="text-lime-700 text-sm">
                    {topic.short_name}
                  </p>
                </div>
                <div className="ml-3 flex items-center h-5">
                  <input
                    id={`${topic.name}-checkbox`}
                    name={`${topic.name}-checkbox`}
                    type="checkbox"
                    className="focus:ring-lime-500 h-4 w-4 text-lime-600 border-gray-300 rounded"
                  />
                </div>
              </div>
            ))}
          </div>
        </fieldset>
      </div>
      <div className="py-6">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-lime-700 hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
        >
          Create deck
        </button>
      </div>
    </div>
  );
}
