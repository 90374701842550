import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { sideNavItems } from "../../constants/Navigation";
import { XIcon, LogoutIcon } from "@heroicons/react/outline";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Avatar from "./Avatar";
import { NavLink } from "react-router-dom";
import { classNames } from "../../lib/utils";

export default function SideNavMenu({ sidebarOpen, setSidebarOpen }) {
  const authContext = useContext(AuthContext);
  let navigate = useNavigate();

  const logoutHandler = () => {
    authContext.logout();
    navigate("/");
  };

  const setCurrent = selectedItem => {
    sideNavItems.forEach(item => {
      item.current = false;
    });
    sideNavItems.forEach(item => {
      if (selectedItem.name === item.name) {
        item.current = true;
      }
    });
  };

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 flex z-40">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div className="flex-shrink-0 flex items-center px-4">
                    <img
                      src="/dashboard-icon.svg"
                      alt="Milnerva"
                      height={40}
                      width={200}
                    />
                  </div>
                  <nav className="mt-5 px-2 space-y-1">
                    {sideNavItems.map(item => (
                      <NavLink
                        key={item.href}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                          "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                        )}
                        onClick={() => {
                          setCurrent(item);
                        }}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? "text-gray-500"
                              : "text-gray-400 group-hover:text-gray-500",
                            "mr-4 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </NavLink>
                    ))}
                  </nav>
                  <div className="px-2">
                    <button
                      className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md"
                      onClick={logoutHandler}
                    >
                      <LogoutIcon
                        className="text-gray-400 group-hover:text-gray-500 
                          mr-4 flex-shrink-0 h-6 w-6"
                        aria-hidden="true"
                      />
                      Sign out
                    </button>
                  </div>
                </div>
                <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                  <Link to="/dashboard/account">
                    <button className="flex-shrink-0 group block">
                      <div className="flex items-center">
                        <div>
                          <Avatar />
                        </div>
                        <div className="ml-3">
                          <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                            Tom Cook
                          </p>
                          <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                            View profile
                          </p>
                        </div>
                      </div>
                    </button>
                  </Link>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
