// eslint-disable-next-line
const local = {
  host: "http://127.0.0.1:8000",
};

// eslint-disable-next-line
const dev = {
  host: "https://dev-api.milnerva.com",
};

// eslint-disable-next-line
const prod = {
  host: "https://api.milnerva.com",
};

export const env = dev;
