import React, { useEffect, useState, useContext } from "react";
import { AxiosContext } from "../../context/AxiosProvider";
import { useParams } from "react-router-dom";
import Loading from "../Loading";
import { InformationCircleIcon, RefreshIcon } from "@heroicons/react/outline";
import FlashCard from "./FlashCard";

const emojis = ["🚀", "🔥", "🤠", "🥳", "💪", "😀", "🇺🇸", "🪖", "🎉", "🎊"];
const messages = [
  "All clear.",
  "Good work!",
  "That's it.",
  "Great job!",
  "All done.",
  "Ta-da!",
  "You're all done.",
  "You did it.",
  "You made it.",
  "All set.",
];

const randomEmoji = () => {
  return emojis[Math.floor(Math.random() * (emojis.length - 1))];
};

const randomMessage = () => {
  return messages[Math.floor(Math.random() * (messages.length - 1))];
};

export default function DeckStudy() {
  let { id } = useParams();
  const axiosContext = useContext(AxiosContext);
  const [cards, setCards] = useState([]);
  const [card, setCard] = useState();
  const [count, setCount] = useState(0);
  // const [iterator, setIterator] = useState(1);
  const [cardIndex, setCardIndex] = useState(1);
  const [emoji, setEmoji] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchCards = async () => {
      try {
        await axiosContext.authAxios
          .get(`/study/decks/${id}/study`)
          .then(res => {
            const data = res.data.cards;
            const newCards = data.new;
            const learningCards = data.learning;
            const reviewingCards = data.reviewing;
            let studyCards = newCards.concat(learningCards, reviewingCards);
            setCards(studyCards);
            setCount(studyCards.length);
            setEmoji(randomEmoji());
            setMessage(randomMessage());
            setCard(studyCards[0]);
            setLoading(false);
          })
          .catch(err => console.log(err));
      } catch (error) {
        throw error;
      }
    };

    fetchCards();
  }, []);

  // const recycleCard = cardIndex => {
  //   deckCards.push(deckCards[cardIndex]);
  // };

  const onAnswerCardHandler = async (cardIndex, response) => {
    let card;
    if (response === -1) {
      card = cards[cardIndex - 1];
    } else {
      card = cards[cardIndex];
    }
    const { id } = card;

    const data = {
      card: id,
      response: response,
    };
    // try {
    //   await axiosContext.authAxios
    //     .post(`/study/card`, data)
    //     .then(res => {})
    //     .catch(err => console.log(err));
    // } catch (error) {
    //   throw error;
    // }
    console.log(data);
    if (response === -1) {
      setCardIndex(cardIndex - 1);
      // setCounter(counter - 1);
    } else {
      if (response !== 0) {
        setCardIndex(cardIndex + 1);
        // setCounter(counter + 1);
      }
    }
    setVisible(false);
    setCard(cards[cardIndex]);
  };

  // const onAnswerCardHandler = () => {
  //   setIterator(iterator + 1);
  //   setCard(cards[iterator]);
  //   setVisible(false);
  // };

  return loading ? (
    <Loading />
  ) : (
    <div className="container px-12 flex flex-col items-center">
      {/* header bar */}
      <div className="flex justify-between w-full py-4">
        <div className="text-base font-semibold px-4"></div>
        <div className="text-base font-semibold px-4"></div>
        <InformationCircleIcon className="h-8 w-8 text-blue-700" />
      </div>
      {/* card */}
      <FlashCard
        prompt={card?.card.prompt}
        answer={card?.card.answer}
        visible={visible}
        setVisible={setVisible}
      />
      {/* footer */}
      <div className="h-28 w-full flex items-center justify-center">
        <div className="flex items-center justify-between space-x-4 sm:px-8">
          <button className="p-3 rounded-full text-white flex items-center justify-center cursor-pointer">
            <RefreshIcon className="h-8 w-8 mb-4 text-blue-700" />
          </button>
          <button
            className="px-4 py-4 rounded-lg bg-red-700 text-white h-12 w-24 flex items-center justify-center"
            onClick={() => onAnswerCardHandler(cardIndex, 0)}
          >
            <span className="text-base font-semibold">Wrong</span>
          </button>
          <button
            className="px-4 py-4 rounded-lg bg-yellow-300 text-white h-12 w-24 flex items-center justify-center"
            onClick={() => onAnswerCardHandler(cardIndex, 4)}
          >
            <span className="text-base font-semibold">Hard</span>
          </button>
          <button
            className="px-4 py-4 rounded-lg bg-green-700 text-white h-12 w-24 flex items-center justify-center"
            onClick={() => onAnswerCardHandler(cardIndex, 5)}
          >
            <span className="text-base font-semibold">Easy</span>
          </button>
          <div className="px-4 py-4 rounded-lg text-gray-700 h-12 w-24 flex items-center justify-center mb-4">
            <span className="text-base font-semibold">
              {cardIndex}/{count}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
