import React, { useContext, useEffect, useState, useCallback } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import SideNavMenu from "../../components/dashboard/SideNavMenu";
import { MenuIcon } from "@heroicons/react/outline";
import { sideNavItems } from "../../constants/Navigation";
import { LogoutIcon } from "@heroicons/react/outline";
import Avatar from "../../components/dashboard/Avatar";
import { Link, useLocation } from "react-router-dom";
import DashboardHome from "./DashboardHome";
import StudyScreen from "./study/StudyScreen";
import { classNames } from "../../lib/utils";
import Publications from "./publications/Publications";
export default function Dashboard() {
  let navigate = useNavigate();
  let location = useLocation();
  const [loading, setLoading] = useState(false);
  const authContext = useContext(AuthContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const logoutHandler = () => {
    authContext.logout();
    navigate("/");
  };

  const loadJWT = useCallback(async () => {
    try {
      setLoading(true);
      const value = await localStorage.getItem("token");
      if (value !== null) {
        const jwt = JSON.parse(value);
        authContext.setAuthState({
          access: jwt.access,
          refresh: jwt.refresh,
          authenticated: true,
        });
      } else {
        authContext.setAuthState({
          access: null,
          refresh: null,
          authenticated: false,
        });
        navigate("/login");
      }

      setLoading(false);
    } catch (error) {
      console.log(`Token Error: ${error.message}`);
      authContext.setAuthState({
        access: null,
        refresh: null,
        authenticated: false,
      });
      setLoading(false);
      navigate("/login");
    }
  }, []);

  const setCurrent = selectedItem => {
    sideNavItems.forEach(item => {
      item.current = false;
    });
    sideNavItems.forEach(item => {
      if (selectedItem.name === item.name) {
        item.current = true;
      }
    });
  };

  useEffect(() => {
    loadJWT();
  }, [loadJWT]);

  if (!authContext?.authState?.authenticated === true) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="h-full bg-gray-50 overflow-y-scroll">
      <SideNavMenu sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <img
                src="/dboard-icon.svg"
                alt="Milnerva"
                height={35}
                width={35}
              />
              <div className="ml-2">
                <span className="font-semibold text-lime-700 text-lg">
                  MILNERVA
                </span>
              </div>
            </div>
            <nav className="mt-5 flex-1 px-2 bg-white space-y-1">
              {sideNavItems.map(item => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-100 text-gray-900"
                      : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                    "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                  )}
                  onClick={() => {
                    setCurrent(item);
                  }}
                >
                  <item.icon
                    className={classNames(
                      item.current
                        ? "text-gray-600"
                        : "text-gray-400 group-hover:text-gray-500",
                      "mr-3 flex-shrink-0 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  {item.name}
                </Link>
              ))}
            </nav>
            <div className="px-2 bg-white">
              <button
                className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer"
                onClick={logoutHandler}
              >
                <LogoutIcon
                  className="text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                  aria-hidden="true"
                />
                Sign out
              </button>
            </div>
          </div>
          <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
            <Link to="/dashboard/account">
              <button className="flex-shrink-0 w-full group block">
                <div className="flex items-center">
                  <div>
                    <Avatar />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                      Tom Cook
                    </p>
                    <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                      View profile
                    </p>
                  </div>
                </div>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="md:pl-64 flex flex-col flex-1">
        <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-50">
          <button
            type="button"
            className="ml-0.5 mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-lime-700 border border-lime-700 hover:text-lime-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-lime-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1">
          <div className="py-6">
            <Routes>
              <Route path="home" element={<DashboardHome />} />
              <Route path="/study/*" element={<StudyScreen />} />
              <Route path="publications" element={<Publications />} />
            </Routes>
          </div>
        </main>
      </div>
    </div>
  );
}
