import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Loading from "../Loading";
import { AxiosContext } from "../../context/AxiosProvider";
import DeckDetailOverlay from "./DeckDetailOverlay";

export default function DeckList() {
  const [decks, setDecks] = useState([]);
  const [deck, setDeck] = useState();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const axiosContext = useContext(AxiosContext);

  useEffect(() => {
    const fetchDecks = async () => {
      try {
        await axiosContext.authAxios
          .get("/study/decks/")
          .then(res => {
            setDecks(res.data);
            setLoading(false);
          })
          .catch(err => console.log(err));
      } catch (error) {
        throw error;
      }
    };
    fetchDecks();
  }, []);

  const onClickHandler = async id => {
    try {
      await axiosContext.authAxios
        .get(`/study/decks/${id}`)
        .then(res => {
          setDeck(res.data);
          setLoading(false);
          setOpen(true);
        })
        .catch(err => console.log(err));
    } catch (error) {
      throw error;
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <div>
      <DeckDetailOverlay open={open} setOpen={setOpen} deck={deck} />
      <h3 className="font-semibold text-2xl">Decks</h3>
      <div className="py-6">
        <ul className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3">
          {decks?.map(deck => (
            <li
              key={deck.id}
              className="col-span-1 rounded-lg shadow cursor-pointer flex items-center justify-between py-4"
              onClick={() => {
                onClickHandler(deck.id);
              }}
            >
              <div className="pl-4 pr-2">
                <h3 className="text-gray-900 text-md font-medium truncate ...">
                  {deck.name}
                </h3>
              </div>
              <div className="pl-2 pr-4">
                <p className="text-gray-500 text-sm text-lime-700 truncate ...">
                  Due
                </p>
                <p className="text-gray-900 text-sm font-semibold truncate">
                  {deck.cards_due}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
