import React from "react";

function Disclaimer() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Disclaimer
            </span>
          </h1>
          <p className="mt-2 text-lg text-gray-500 leading-8 text-center">
            Last updated July 20, 2022
          </p>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <h2>Website Disclaimer</h2>
          <p>
            The information provided by Milnerva LLC ("we", "us", or "our") on
            https://www.milnerva.com (the "Site") and our mobile application is
            for general informational purposes only. All information on the Site
            and our mobile application is provided in good faith, however we
            make no representation or warranty of any kind, express or implied,
            regarding the accuracy, adequacy, validity, reliability,
            availability, or completeness of any information on the Site or our
            mobile application. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILIY
            TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF
            THE USE OF THE SITE OR OUR MOBILE APPLICATION OR RELIANCE ON ANY
            INFORMATION PROVIDED ON THE SITE AND OUR MOBILE APPLICATION. YOUR
            USE OF THE SITE AND OUR MOBILE APPLICATION AND YOUR RELIANCE ON ANY
            INFORMATION ON THE SITE AND OUR MOBILE APPLICATION IS SOLELY AT YOUR
            OWN RISK.
          </p>
          <h2>External Links Disclaimer</h2>
          <p>
            The Site and our mobile application may contain (or you may be sent
            through the Site or our mobile application) links to other websites
            or content belonging to or originating from third parties or links
            to websites and features in banners or other advertising. Such
            external links are not investigated, monitored, or checked for
            accuracy, adequacy, validity, reliability, availability, or
            completeness by us. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
            RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION
            OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY
            WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE
            WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING
            ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR
            SERVICES.
          </p>
          <h2>Professional Disclaimer</h2>
          <p>
            The Site cannot and does not contain military advice. The military
            information is provided for general information and educational
            purposes only and is not a substitute for professional advice.
            Accordingly, before taking any actions based upon such information,
            we encourage you to consult with the proper professionals. We do not
            provide any kind of military advice. THE USE OR RELIANCE OF ANY
            INFORMATION CONTAINED ON THE SITE OR OUR MOBILE APPLICATION IS
            SOLELY AT YOUR OWN RISK.
          </p>
          <h2>Affiliates Disclaimer</h2>
          <p>
            The Site and our mobile application may contain links to affiliate
            websites, and we receive an affiliate commission for any purchases
            made by you on the affiliate website using such links.
          </p>

          <p>
            We are a participant in the Amazon Services LLC Associates Program,
            an affiliate advertising program designed to provide a means for us
            to earn advertising fees by link to Amazon.com and affiliated
            websites.
          </p>
          <h2>Testimonials Disclaimer</h2>
          <p>
            The Site may contain testimonials by users of our products and/or
            services. These testimonials reflect the real-life experiences and
            opinions of such users. However, the experiences are personal to
            those particular users, and may not necessarily be representative of
            all users of our products and/or services. We do not claim, and you
            should not assume, that all users will have the same experiences.
            YOUR INDIVIDUAL RESULTS MAY VARY.
          </p>
          <p>
            The testimonials on the Site are submitted in various forms such as
            text, audio and/or video, and are reviewed by us before being
            posted. They appear on the Site verbatim as given by the users,
            except for the correction of grammar or typing errors. Some
            testimonials may have been shortened for the sake of brevity where
            the full testimonial contained extraneous information not relevant
            to the general public.
          </p>
          <p>
            The views and opinions contained in the testimonials belong solely
            to the individual user and do not reflect our views and opinions. We
            are not affiliated with users who provide testimonials, and users
            are not paid or otherwise compensated for their testimonials.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Disclaimer;
