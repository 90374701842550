import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  UsersIcon,
} from "@heroicons/react/outline";

export const sideNavItems = [
  { name: "Dashboard", href: "/dashboard/home", icon: HomeIcon, current: true },
  {
    name: "Study",
    href: "/dashboard/study",
    icon: InboxIcon,
    current: false,
  },
  {
    name: "Topics",
    href: "/dashboard/topics",
    icon: FolderIcon,
    current: false,
  },
];

export const oldNavigation = [
  { name: "Dashboard", href: "/dashboard/", icon: HomeIcon, current: true },
  { name: "Team", href: "/dashboard/team", icon: UsersIcon, current: false },
  { name: "Projects", href: "", icon: FolderIcon, current: false },
  { name: "Calendar", href: "/dashboard/", icon: CalendarIcon, current: false },
  {
    name: "Topics",
    href: "/dashboard/topics",
    icon: InboxIcon,
    current: false,
  },
  { name: "Reports", href: "/dashboard/", icon: ChartBarIcon, current: false },
];
