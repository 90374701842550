import React, { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { AxiosContext } from "../context/AxiosProvider";
import { Link, useNavigate } from "react-router-dom";
import { classNames, validUsername, validPassword } from "../lib/utils";

export default function Login() {
  const authContext = useContext(AuthContext);
  const { publicAxios } = useContext(AxiosContext);
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  let navigate = useNavigate();

  const [inputs, setInputs] = useState({
    username: { value: "", isValid: true },
    password: { value: "", isValid: true },
  });

  function inputChangedHandler(inputIdentifier, enteredValue) {
    setInputs(curinputs => {
      return {
        ...curinputs,
        [inputIdentifier]: { value: enteredValue, isValid: true },
      };
    });
  }

  const onSubmit = async loginData => {
    setInvalidCredentials(false);
    try {
      const response = await publicAxios.post("/auth/jwt/create", {
        username: loginData.username,
        password: loginData.password,
      });
      const { access, refresh } = response.data;
      authContext.setAuthState({
        access: access,
        refresh: refresh,
        authenticated: true,
      });

      await localStorage.setItem(
        "token",
        JSON.stringify({
          access: access,
          refresh: refresh,
        })
      );
      navigate("/dashboard");
    } catch (error) {
      setInvalidCredentials(true);
    }
  };

  function submitHandler(e) {
    e.preventDefault();
    const loginData = {
      username: inputs.username.value,
      password: inputs.password.value,
    };

    const usernameIsValid = validUsername(loginData.username);
    const passwordIsValid = validPassword(loginData.password);

    if (!usernameIsValid || !passwordIsValid) {
      setInputs(curInputs => {
        return {
          username: {
            value: curInputs.username.value,
            isValid: usernameIsValid,
          },
          password: {
            value: curInputs.password.value,
            isValid: passwordIsValid,
          },
        };
      });
      return;
    }

    onSubmit(loginData);
  }

  return (
    <div className="p-0 h-full bg-gray-50">
      <main>
        <div className="min-h-full  flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <Link to="/">
              <img
                className="mx-auto h-12 w-auto cursor-pointer"
                src="hero-icon.png"
                alt="Milnerva"
              />
            </Link>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Sign in to your account
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <form className="space-y-4" action="#" method="POST">
                <div>
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Username
                  </label>
                  <div className="mt-1">
                    <input
                      id="username"
                      name="username"
                      type="username"
                      autoComplete="username"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-lime-500 focus:border-lime-500 sm:text-sm"
                      onChange={e => {
                        inputChangedHandler("username", e.target.value);
                      }}
                      value={inputs.username.value}
                    />
                    <div>
                      <p
                        className={classNames(
                          inputs.username.isValid
                            ? "text-white"
                            : "text-red-500",
                          "text-sm mt-1 ml-1"
                        )}
                      >
                        Check your username and try again
                      </p>
                    </div>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-lime-500 focus:border-lime-500 sm:text-sm"
                      onChange={e => {
                        inputChangedHandler("password", e.target.value);
                      }}
                      value={inputs.password.value}
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div>
                    <p
                      className={classNames(
                        inputs.username.isValid ? "text-white" : "text-red-500",
                        "text-sm "
                      )}
                    >
                      Invalid password
                    </p>
                  </div>

                  <div className="text-sm">
                    <Link to="/password-reset">
                      <button className="font-medium text-lime-600 hover:text-lime-500">
                        Forgot your password?
                      </button>
                    </Link>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-md font-medium text-white bg-lime-700 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
                    onClick={submitHandler}
                  >
                    Sign in
                  </button>
                </div>

                <div className="w-full flex items-center justify-center">
                  <span
                    className={classNames(
                      invalidCredentials
                        ? "text-red-500 text-sm"
                        : "text-white text-sm"
                    )}
                  >
                    Invalid credentials
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
