import { Link } from "react-router-dom";
import React from "react";
import { PlusIcon } from "@heroicons/react/outline";

export default function CreateDeckButton() {
  return (
    <div className="inline-flex">
      <Link to="/dashboard/study/create">
        <button className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-lime-700 hover:text-lime-600">
          Create deck
        </button>
      </Link>
    </div>
  );
}
