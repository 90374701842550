import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import Home from "./views/Home";
import reportWebVitals from "./reportWebVitals";
import NotFound from "./views/NotFound";
import Login from "./views/Login";
import Register from "./views/Register";
import Dashboard from "./views/dashboard/Dashboard";
import { AuthProvider } from "./context/AuthContext";
import { AxiosProvider } from "./context/AxiosProvider";
import PasswordReset from "./views/PasswordReset";
import UsernameReset from "./views/UsernameReset";
import Disclaimer from "./views/Disclaimer";
import PrivacyPolicy from "./views/PrivacyPolicy";
import TermsOfUse from "./views/TermsOfUse";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <AxiosProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/reset/:token" element={<PasswordReset />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsOfUse />} />
            <Route path="/dashboard/*" element={<Dashboard />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </AxiosProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
