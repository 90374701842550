import React, { createContext, useContext } from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { env } from "../config";

const AxiosContext = createContext();
const { Provider } = AxiosContext;

const AxiosProvider = props => {
  const authContext = useContext(AuthContext);
  const baseURL = env.host;

  const authAxios = axios.create({
    baseURL: baseURL,
  });

  const publicAxios = axios.create({
    baseURL: baseURL,
  });

  authAxios.interceptors.request.use(
    config => {
      if (!config.headers.Authorization) {
        config.headers.Authorization = `JWT ${authContext.getAccessToken()}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  const refreshAuthLogic = async failedRequest => {
    const data = {
      refresh: authContext.authState.refresh,
    };

    const options = {
      method: "POST",
      data,
      url: `${baseURL}/auth/jwt/refresh`,
    };

    return axios(options)
      .then(async tokenRefreshResponse => {
        failedRequest.response.config.headers.Authorization =
          "JWT " + tokenRefreshResponse.data.access;

        authContext.setAuthState({
          ...authContext.authState,
          access: tokenRefreshResponse.data.access,
        });

        await localStorage.setItem(
          "token",
          JSON.stringify({
            access: tokenRefreshResponse.data.access,
            refresh: authContext.authState.refresh,
          })
        );

        return Promise.resolve();
      })
      .catch(e => {
        authContext.setAuthState({
          access: null,
          refresh: null,
        });
      });
  };

  createAuthRefreshInterceptor(authAxios, refreshAuthLogic, {});

  return (
    <Provider
      value={{
        authAxios,
        publicAxios,
      }}
    >
      {props.children}
    </Provider>
  );
};

export { AxiosContext, AxiosProvider };
