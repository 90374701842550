import React, { useRef, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { AxiosContext } from "../context/AxiosProvider";

export default function PasswordReset() {
  let params = useParams();
  const inputRef = useRef();
  const { publicAxios } = useContext(AxiosContext);
  const [submitted, setSubmitted] = useState(false);

  const onSubmitHandler = async e => {
    e.preventDefault();
    let data = {
      token: params.token,
      password: inputRef.current.value,
    };

    try {
      await publicAxios.post("/user/password/reset", data).then(response => {
        if (response.status === 200) {
          setSubmitted(true);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return submitted ? (
    <div className="p-0 h-full bg-gray-50">
      <main>
        <div className="min-h-full flex flex-col justify-center py-20 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-12 w-auto"
              src="/hero-icon.png"
              alt="Milnerva"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Password changed
            </h2>
            <p className="mt-2 text-center text-lg text-gray-600">
              You may now close this window.
            </p>
          </div>
        </div>
      </main>
    </div>
  ) : (
    <div className="p-0 h-full bg-gray-50">
      <main>
        <div className="min-h-full flex flex-col justify-center py-20 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-12 w-auto cursor-pointer"
              src="/hero-icon.png"
              alt="Milnerva"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Choose new password
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <form className="space-y-6" action="#" method="POST">
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    New password
                  </label>
                  <div className="mt-1">
                    <input
                      ref={inputRef}
                      id="password"
                      name="password"
                      type="password"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-lime-500 focus:border-lime-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-lime-700 hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
                    onClick={onSubmitHandler}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
